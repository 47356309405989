import { getFromUrl } from './getParamFromSearchQuery';

export function combineMarketingEventProperties() {
  return {
    utm_source: getFromUrl.utmSource(),
    utm_medium: getFromUrl.utmMedium(),
    utm_content: getFromUrl.utmContent(),
    utm_term: getFromUrl.utmTerm(),
    campaign_id: getFromUrl.campaignId(),
    fbclid: getFromUrl.fbclid(),
    ttclid: getFromUrl.ttclid(),
    gclid: getFromUrl.gclid(),
    gbraid: getFromUrl.gbraid(),
  };
}
