export const fastExpressCategories = [
  'cold and flu',
  'stomach intestines liver',
  'inflammation and infections',
  'dietary supplements',
  'healing creams',
  'obstetrics and gynecology',
  'antimicrobial drugs',
  'cardiovascular system',
  'neurology',
  'vitamins',
  'disinfectants',
  'boost immunity',
  'vision',
  'allergy',
  'syringes needles and injectors',
  'endocrinology',
  'bandaging materials',
  'muscles bones joints',
  'skin hair nails',
  'cosmetical tools',
  'phytoteas and herbal preparations',
  'contact lenses',
  'bandages and orthoses',
  'nebulizers',
  'condoms',
  'gynecological tests',
  'blood pressure monitors',
  'incontinence protective briefs and diapers',
  'lubricants',
  'baby nasal aspirators',
  'analysis containers',
  'soaking solutions',
  'accessories for blood glucose monitors',
  'medical thermometers',
  'syringes and enemas',
  'lice removal products',
  'enteral nutrition',
  'feces bag',
  'disinfectant wipes',
  'medical diapers and sheets',
  'medical face masks',
  'canes and crutches',
  'medical gloves',
  'heating pads',
  'orthopedic corsets',
  'hand sanitizers',
  'shoe covers',
  'medicine storage boxes',
  'optics accessories',
  'incontinence pads',
  'disinfection germicidal lamps',
  'orthopedic insoles',
  'disposable medical instruments',
  'virus blockers',
  'medical compression hosiery',
  'blood glucose monitors',
  'anti-decubitus mattresses and pillows',
  'pulse oximeters',
  'hygiene and care of dentures',
  'lice combing tools',
  'computer glasses',
  'orthopedic products',
  'supports and walkers',
  'ear correctors',
  'patient care products',
  'posture correctors',
  'oxygen cylinders',
  'physiotherapy equipment',
  'medical caps',
  'inflatable nursing baths',
  'rapid tests for covid-19',
  'braces',
  'hearing aid consumables',
  'breathalyzers',
  'allergy devices',
  'laboratory glassware',
  'conductive gels',
  'rehabilitation accessories',
  'sanitary chairs',
  'surgical aspirator',
  'surgical instruments',
  'stethoscopes',
  'hearing aid accessories',
  'magnetic therapy devices',
  'disposable trousers',
  'accessories for blood pressure monitors',
  'dental mouth guards',
  'hygienic garment liners',
  'nitrate meters',
  'x-ray films',
  'oxygen accessories',
  'insulin pumps',
  'medical tripod',
  'thermo containers medical',
  'rehabilitation simulators',
  'surface disinfectants',
];
