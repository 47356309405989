import {
  getFormattedDate,
  guardDateISOFormat,
  splitDateByDayMonthYear,
} from 'ks-utilities/lib/getFormattedDate';
import { getFormattedDeliveryDate } from 'ks-utilities/lib/getFormattedDeliveryDate';
import { setTimeZone } from 'ks-utilities/lib/setTimeZone';

export enum DateType {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
}

interface DateStrings {
  today: string;
  tomorrow: string;
}

export enum WeekdayPlacement {
  BEFORE = 'before',
  AFTER = 'after',
}

export enum MonthsType {
  'M1' = 'JANUARY',
  'M2' = 'FEBRUARY',
  'M3' = 'MARCH',
  'M4' = 'APRIL',
  'M5' = 'MAY',
  'M6' = 'JUNE',
  'M7' = 'JULY',
  'M8' = 'AUGUST',
  'M9' = 'SEPTEMBER',
  'M10' = 'OCTOBER',
  'M11' = 'NOVEMBER',
  'M12' = 'DECEMBER',
  'OM1' = 'ON.JANUARY',
  'OM2' = 'ON.FEBRUARY',
  'OM3' = 'ON.MARCH',
  'OM4' = 'ON.APRIL',
  'OM5' = 'ON.MAY',
  'OM6' = 'ON.JUNE',
  'OM7' = 'ON.JULY',
  'OM8' = 'ON.AUGUST',
  'OM9' = 'ON.SEPTEMBER',
  'OM10' = 'ON.OCTOBER',
  'OM11' = 'ON.NOVEMBER',
  'OM12' = 'ON.DECEMBER',
  'UM1' = 'UNTIL.JANUARY',
  'UM2' = 'UNTIL.FEBRUARY',
  'UM3' = 'UNTIL.MARCH',
  'UM4' = 'UNTIL.APRIL',
  'UM5' = 'UNTIL.MAY',
  'UM6' = 'UNTIL.JUNE',
  'UM7' = 'UNTIL.JULY',
  'UM8' = 'UNTIL.AUGUST',
  'UM9' = 'UNTIL.SEPTEMBER',
  'UM10' = 'UNTIL.OCTOBER',
  'UM11' = 'UNTIL.NOVEMBER',
  'UM12' = 'UNTIL.DECEMBER',
  'FM1' = 'FROM.JANUARY',
  'FM2' = 'FROM.FEBRUARY',
  'FM3' = 'FROM.MARCH',
  'FM4' = 'FROM.APRIL',
  'FM5' = 'FROM.MAY',
  'FM6' = 'FROM.JUNE',
  'FM7' = 'FROM.JULY',
  'FM8' = 'FROM.AUGUST',
  'FM9' = 'FROM.SEPTEMBER',
  'FM10' = 'FROM.OCTOBER',
  'FM11' = 'FROM.NOVEMBER',
  'FM12' = 'FROM.DECEMBER',
}

const WEEKDAY_TRANSLATIONS = {
  SUNDAY: 'DAY.SUNDAY.SHORT',
  MONDAY: 'DAY.MONDAY.SHORT',
  TUESDAY: 'DAY.TUESDAY.SHORT',
  WEDNESDAY: 'DAY.WEDNESDAY.SHORT',
  THURSDAY: 'DAY.THURSDAY.SHORT',
  FRIDAY: 'DAY.FRIDAY.SHORT',
  SATURDAY: 'DAY.SATURDAY.SHORT',
};

export let localeDateService: any = {
  getFormattedDate: (
    dateISO: string,
    withYear?: boolean,
    withTodayTomorrow?: boolean
  ) => getFormattedDate(dateISO, withYear, withTodayTomorrow),
  getFormattedDeliveryDate: (
    dateISO: string,
    t?: {
      today: string;
      tomorrow: string;
    }
  ) => getFormattedDeliveryDate(dateISO, t),
};

export const initLocaleDateStringService = (m: any) => {
  localeDateService = new LocaleDateStringService(m);
};

export class LocaleDateStringService {
  m: any = null;

  constructor(m) {
    this.m = m;
  }

  getLocaleDateStringDefault(date: Date): string {
    return `${date.getDate()} ${this.m.t.get(
      MonthsType['M' + (date.getMonth() + 1)]
    )}`;
  }

  getLocaleUntilDateString(date: Date): string {
    return `${date.getDate()} ${this.m.t.get(
      MonthsType['UM' + (date.getMonth() + 1)]
    )}`;
  }

  getLocaleDateString(date: Date): string {
    return `${date.getDate()} ${this.m.t.get(
      MonthsType['OM' + (date.getMonth() + 1)]
    )}`;
  }

  getLocaleFromDateString(date: Date): string {
    return `${date.getDate()} ${this.m.t.get(
      MonthsType['FM' + (date.getMonth() + 1)]
    )}`;
  }

  /**
   * data formatter, return data in format like "21 february"
   * @param dateISO - data in YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS... formats
   * @param withYear - false by default, if true - return data with year ("21 february, 2022" for example)
   * @param withTodayTomorrow - true by default - return "today" or "tomorrow" text if dates is today or tomorrow
   */
  getFormattedDate(
    dateISO: string,
    withYear: boolean = false,
    withTodayTomorrow = true
  ): string {
    if (!guardDateISOFormat(dateISO)) {
      return '';
    }

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const { year, month, day } = splitDateByDayMonthYear(dateISO);

    const date = new Date(+year, +month - 1, +day);

    const localeDate = this.getLocaleDateString(date);
    if (localeDate === this.getLocaleDateString(today) && withTodayTomorrow) {
      return DateType.TODAY;
    }

    if (
      localeDate === this.getLocaleDateString(tomorrow) &&
      withTodayTomorrow
    ) {
      return DateType.TOMORROW;
    }

    return withYear ? `${localeDate}, ${year}` : localeDate;
  }

  getFormattedUntilDate(dateISO: string): string {
    if (!guardDateISOFormat(dateISO)) {
      return '';
    }

    const { year, month, day } = splitDateByDayMonthYear(dateISO);

    const date = new Date(+year, +month - 1, +day);
    const localeDate = this.getLocaleUntilDateString(date);
    return localeDate;
  }

  getFormattedDateDefault(dateISO: string): string {
    if (!guardDateISOFormat(dateISO)) {
      return '';
    }

    const { year, month, day } = splitDateByDayMonthYear(dateISO);

    const date = new Date(+year, +month - 1, +day);
    const localeDate = this.getLocaleDateStringDefault(date);
    return localeDate;
  }

  private getSpecialDateLabel(
    formattedDate: string,
    dateStrings: DateStrings
  ): string | null {
    if (formattedDate === DateType.TODAY) {
      return dateStrings.today;
    }
    if (formattedDate === DateType.TOMORROW) {
      return dateStrings.tomorrow;
    }
    return null;
  }

  getFormattedDeliveryDate(
    dateISO: string,
    dateStrings: DateStrings = { today: null, tomorrow: null }
  ): string {
    const formattedDate = this.getFormattedDate(dateISO);
    return (
      this.getSpecialDateLabel(formattedDate, dateStrings) ?? formattedDate
    );
  }

  getWeekdayName(dateISO: string): string {
    const weekdayNames = Object.keys(WEEKDAY_TRANSLATIONS);
    const date = new Date(setTimeZone(dateISO));
  
    const weekdayIndex = date.getDay();
    const weekdayKey = weekdayNames[weekdayIndex];

    return this.m.t.get(WEEKDAY_TRANSLATIONS[weekdayKey]);
  }

  getDeliveryDateWithWeekday(
    dateISO: string,
    dateStrings: DateStrings = { today: null, tomorrow: null },
    weekdayPlacement: WeekdayPlacement = WeekdayPlacement.BEFORE
  ): string {
    const formattedDate = this.getFormattedDate(dateISO);
    const specialDateLabel = this.getSpecialDateLabel(
      formattedDate,
      dateStrings
    );

    if (specialDateLabel) {
      return specialDateLabel;
    }

    const localizedShortWeekday = this.getWeekdayName(dateISO);

    return weekdayPlacement === WeekdayPlacement.BEFORE
      ? `${localizedShortWeekday}, ${formattedDate}`
      : `${formattedDate}, ${localizedShortWeekday}`;
  }
}
