import { getGeoHeaders } from '../helpers/getGeoHeaders';
import { SessionStorageModel } from '../StorageModel/SessionStorageModel';

const usersGeolocationStorageKey = 'usersCurrentGeolocation';

export enum GeolocationHeaders {
  LATITUDE = 'X-Latitude',
  LONGITUDE = 'X-Longitude',
}

export class UserGeolocationStorageService {
  sessionStorageModel: SessionStorageModel;

  constructor() {
    this.sessionStorageModel = new SessionStorageModel();
  }

  get storedGeoData() {
    return this.sessionStorageModel.getValue(usersGeolocationStorageKey) || null;
  }

  saveGeolocationToSessionStorage(latitude: number, longitude: number, addressId?: string) {
    if (latitude && longitude) {
      this.sessionStorageModel.saveValue({
        name: usersGeolocationStorageKey,
        value: JSON.stringify({
          latitude,
          longitude,
          addressId,
        }),
      });
    }
  }

  removeGeolocationFromSessionStorage() {
    this.sessionStorageModel.removeValue(usersGeolocationStorageKey);
  }

  getGeolocationHeadersFromSession() {
    if (process.env.NODE_ENV === 'development' || !this.storedGeoData) {
      return {};
    }

    const parsedGeoData = JSON.parse(this.storedGeoData);

    return getGeoHeaders(parsedGeoData.latitude, parsedGeoData.longitude);
  }

  getAddressIdFromSession() {
    if (!this.storedGeoData) {
      return null;
    }

    const parsedGeoData = JSON.parse(this.storedGeoData);

    return parsedGeoData.addressId;
  }
}
