import { GeolocationHeaders } from '../services/UserGeolocationStorageService';

export function getGeoHeaders(lat, lon) {
  if (lat && lon) {
    return {
      headers: {
        [GeolocationHeaders.LATITUDE]: lat.toString(),
        [GeolocationHeaders.LONGITUDE]: lon.toString(),
      },
    };
  }

  return {};
}
