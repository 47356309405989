import { CONSTANTS } from '../const/Constants';
import { IntersectionObserverLeaveService } from './IntersectionObserverLeaveService';
import { IntersectionObserverService } from './intersectionObserverService';
import { isIOs } from './osService';

export enum ProductCoverMediaType {
  JPEG = 'JPEG',
  MP4 = 'MP4',
  GIF = 'GIF',
}

export enum ProductCoverType {
  REVIEW = 'r',
  TEASER = 't',
}

interface IAddObserversParams {
  element: HTMLMediaElement;
  observerEnterCallback: (data?: unknown) => void;
  observerLeaveCallback: (data?: unknown) => void;
}

export class ProductCoverService {
  isCoverExists: boolean;

  intersectionObserverService: IntersectionObserverService;
  intersectionObserverLeaveService: IntersectionObserverLeaveService;

  constructor() {
    this.intersectionObserverService = new IntersectionObserverService({
      threshold: 0.5,
    });
    this.intersectionObserverLeaveService =
      new IntersectionObserverLeaveService({ threshold: 0.5 });

    // * Этот слушатель фул-скрина нужен для кейса с андройдом
    // Когда видео открывают на полный экран браузер делает repaint несколько раз
    // из-за чего некоторые элементы скрываются хотя в DOM они есть (этот хак позволяет починить эту ошибку)
    // * Доп манипуляции со стилями вызывают repaint
    if (!isIOs()) {
      document.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          document.body.style.display = 'none';
          void document.body.offsetHeight;
          document.body.style.display = '';
        }
      });
    }
  }

  /**
   * @description Возвращает ссылку на обложку определенного типа
   *
   * @param {string} mediaType Медиа тип обложки
   * @param {ProductCoverType} type Тип обложки (тизер, обзор)
   * @param {string} productId Id продукта
   * @param {string} coverUrl Эндпоинт откуда брать обложку
   * @returns {string} Ссылка на обложку
   */
  getCoverUrl(
    mediaType: ProductCoverMediaType,
    type: ProductCoverType = ProductCoverType.REVIEW,
    productId: string,
    coverUrl: string = CONSTANTS.initProductCover.coverUrl
  ): string {
    return `${coverUrl}/${type}/${productId}?e=${mediaType}`;
  }

  getCoverPoster(
    productId: string,
    type = ProductCoverType.REVIEW,
    posterUrl = CONSTANTS.initProductCover.posterUrl
  ): string {
    return `${posterUrl}/${type}/${productId}?e=${ProductCoverMediaType.JPEG}`;
  }

  /**
   * @description Запрашиваем наличие обложки чтобы понимать рендерить ли ее
   *
   * @param m Mithril для реализации запроса
   * @param {string} productId SKU продукта
   *
   * @returns {Promise<boolean>} если обложка есть вернет { exists: true }
   */
  async checkIsCoverExists(
    m,
    productId: string,
    type: ProductCoverType
  ): Promise<boolean> {
    const url = `${CONSTANTS.initProductCover.existUrl}/${type}/${productId}`;

    try {
      const response = await m.request({
        method: 'GET',
        url,
      });

      if (!response) {
        return false;
      }

      return response.exists;
    } catch {
      return false;
    }
  }

  /**
   * Удаляет элемент из observerElements,
   * метод нужен в случае с модалками когда элемент пересоздается
   * а в observerElements все еще лежит копия этого элемента
   *
   * @param {String} id Id HTML элемента
   */
  removeElementByIdFromObserved(id: string) {
    this.intersectionObserverService.removeElementFromObserverElements(id);
    this.intersectionObserverLeaveService.removeElementFromObserverElements(id);
  }

  /**
   * Добавляет обзерверы когда обложка попадает и покидает viewport
   *
   * @param {IAddObserversParams} params
   * @param {HTMLElement} params.element - Элемент DOM, который необходимо наблюдать
   * @param {Function} [params.observerEnterCallback] - Колбек когда элемент попадает в область просмотра
   * @param {Function} [params.observerLeaveCallback] - Колбек когда элемент покидает область просмотра
   *
   */
  addObservers({
    element,
    observerEnterCallback,
    observerLeaveCallback,
  }: IAddObserversParams) {
    if (observerEnterCallback) {
      this.intersectionObserverService.addObserver({
        id: element.id,
        element,
        callback: observerEnterCallback,
      });
    }

    if (observerLeaveCallback) {
      this.intersectionObserverLeaveService.addObserver({
        id: element.id,
        element,
        callback: observerLeaveCallback,
      });
    }
  }
}

export const productCoverServiceInstance = new ProductCoverService();
