import formatterNumber from 'ks-utilities/lib/formatterNumber';

import { FilterDeliveryTime } from '../../const/FilterDelivery';
import { mutateMonthlyPayment } from '../../helpers/mutateMonthlyPayment';
import {
  convertDeliveryDateOfTeaser,
  IDeliveryDateTeaserTranslation,
} from './convertDeliveryDateOfTeaser';
import { mutatePromoBadges } from './mutatePromoBadges';

export interface IMutateProductCardTranslate
  extends IDeliveryDateTeaserTranslation {
  priceLiteral: string;
}

interface IMutateProductCards {
  products: IItemCard[];
  offers: IOfferGateway[];
  merchantId?: string;
  deliveryKey?: FilterDeliveryTime;
  translation: IMutateProductCardTranslate;
  pcmDataList?: any;
}

interface IMutateProductCard {
  card: IItemCard;
  offer: IOfferGateway;
  merchantId?: string;
  deliveryKey?: FilterDeliveryTime;
  translation: IMutateProductCardTranslate;
}

export enum ProductLinkParam {
  MERCHANT_ID = 'm',
  DELIVERY_FILTER = 'df',
}

export function mutateProductCard({
  card,
  offer,
  merchantId,
  deliveryKey,
  translation,
}: IMutateProductCard): void {
  const isBestPrice = !!merchantId || offer.price === card.unitPrice;

  const deliveryData = convertDeliveryDateOfTeaser({
    deliveryOptions: offer.deliveryOptions,
    translation,
    isBestPrice,
  });

  card.delivery = deliveryData.delivery;
  card.deliveryDate = deliveryData.deliveryDate;

  if (offer.badges) {
    card.promo = mutatePromoBadges(offer.badges);
  }

  if (!(merchantId || deliveryKey)) {
    return;
  }

  card.unitPriceBeforeDiscount = offer.priceBeforeDiscount;
  card.discount = offer.discount ?? 0;
  card.priceFormatted = `${formatterNumber(offer.price)} ${
    translation.priceLiteral
  }`;
  card.unitPrice = offer.price;
  card.unitSalePrice = offer.price;
  mutateMonthlyPayment(card, translation.priceLiteral);

  if (merchantId) {
    card.merchantId = merchantId;
    card.shopLink = `${card.shopLink}&${ProductLinkParam.MERCHANT_ID}=${merchantId}`;
  }

  if (deliveryKey) {
    card.shopLink = `${card.shopLink}&${ProductLinkParam.DELIVERY_FILTER}=${deliveryKey}`;
  }

  card.supplier = offer.merchantName;
}

export function mutateProductCards({
  products,
  offers,
  merchantId,
  deliveryKey,
  translation,
}: IMutateProductCards): void {
  products.forEach((card) => {
    const offer = offers.find(
      (item: IOfferGateway) =>
        card.id === item.masterSku || card.id === item.groupedSku
    );

    if (!offer) {
      return;
    }

    mutateProductCard({
      card,
      offer,
      merchantId,
      deliveryKey,
      translation,
    });
  });
}

export function mutateProductCardsByOffer({
  products,
  offers,
  deliveryKey,
  translation,
  pcmDataList = [],
}: IMutateProductCards): IItemCard[] {
  return products.filter((card) => {
    const offer = offers.find(
      (item: IOfferGateway) =>
        card.id === item.masterSku || card.id === item.groupedSku
    );

    if (!offer) {
      return false;
    }

    const merchantId = pcmDataList.find(
      (item) => item.productId === card.id
    )?.merchantId;

    mutateProductCard({
      card,
      offer,
      merchantId,
      deliveryKey,
      translation,
    });
    card.merchantId = offer.merchantId;

    return true;
  });
}
