import {
  IElementObserver,
  IntersectionObserverService,
} from './intersectionObserverService';

export class IntersectionObserverLeaveService extends IntersectionObserverService {
  onIntersection(entities: IntersectionObserverEntry[]) {
    entities.forEach((item: IntersectionObserverEntry) => {
      if (!item.isIntersecting) {
        const id: string = item.target.id;
        const elementIndex = this.observerElements.findIndex(
          (element) => element.id === id
        );
        if (!~elementIndex) {
          return;
        }

        const result = this.observerElements[elementIndex];

        if (!result?.callback) {
          return;
        }

        result.callback();

        if (!result.callOnlyOnce) {
          return;
        }

        this.observer.unobserve(item.target);
        this.observerElements.splice(elementIndex, 1);
      }
    });
  }
}

const intersectionObserverLeaveService = new IntersectionObserverLeaveService();
export const addObserverLeave = (data: IElementObserver) =>
  intersectionObserverLeaveService.addObserver(data);
