export function getAuctionBannerData(blockData: IParamAuctionBlock) {
  return {
    ads_unique_id: blockData.eventId,
    block_id: blockData.code,
    block_name: blockData.title,
    block_style: blockData.style,
    block_type: blockData.type,
    block_position: blockData.position,

    banner_id: blockData.list[0]?.code,
    brand: blockData.list[0]?.brand,
    campaign_id: blockData.list[0]?.campaignId,
    is_promoted: !!blockData.list[0]?.promoted,
    ads_supplier_id: blockData.list[0]?.merchant,
    listing_position: blockData.listingPosition,
    listitems_total_count: blockData.list.length
  };
}
