// TODO: Хелпер на время теста (когда обложки будут приходить с бэка надо будет удалить)
import { CONSTANTS } from '../../const/Constants';
import {
  ProductCoverMediaType,
  productCoverServiceInstance,
  ProductCoverType,
} from '../../services/productCoverService';

export async function setCover(cards: IItemCard[], m): Promise<void> {
  try {
    const url = `${CONSTANTS.initProductCoverInListing.existUrl}/${ProductCoverType.TEASER}`;

    const response = await m.request({
      url,
      params: {
        o: cards.map((card) => card.id).toString(),
      },
    });

    if (!response) {
      return;
    }

    cards.forEach((card) => {
      card.cover =
        // * status === 'OK' означает что видео уже загружено и готово к использованию
        // * так же есть статус 'NEW' который говорит о том что видео есть, но еще обрабатывается
        response[card.id]?.status === 'OK'
          ? {
              video: productCoverServiceInstance.getCoverUrl(
                ProductCoverMediaType.MP4,
                ProductCoverType.TEASER,
                card.id,
                CONSTANTS.initProductCoverInListing.coverUrl
              ),
              poster:
                card.previewImages?.[0].large ||
                productCoverServiceInstance.getCoverPoster(
                  card.id,
                  ProductCoverType.TEASER
                ),
            }
          : null;
    });
  } catch (error) {
    console.error(error);
  }
}
