export class RefundsEndpoint {
  static defaultPath = '/yml/ks-refund/api/v1';

  /**
   * order-refund
   */

  refundCheck = '/mobile/refund/check/:orderId';
  refundReasons = '/mobile/reasons/product/:productSku';
  refundUploadImage = '/image/upload';
  refundSend = '/mobile/refund/create';
  refundsList = '/mobile/refunds';
  refund = '/mobile/refund/:id';
  refundByCode = '/mobile/refund/code/:code';
  refundApprovalDispute = '/mobile/refund/:refundId/dispute/approval/start';
  refundDecisionDispute = '/mobile/refund/:refundId/dispute/decision/start';
  refundsCount = '/mobile/refunds/count/:process';
  refundCancelApplication = '/mobile/refund/:refundId/cancel';
  refundDelivery = '/mobile/refund/:refundId/delivery';
  refundSlots = '/mobile/refund/:refundId/slots';
  refundImageUpload = '/upload';
  refundImageRemove = '/remove';

  constructor(path = RefundsEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
