const AL_APPLINK_DATA = 'al_applink_data'; // Ключ для доступа к параметрам deep linking от Meta Platforms (Facebook), содержащимся в URL

function getParamValueFromAlApplink(params, paramName: string): string | null {
  try {
    const alApplinkDataObj = JSON.parse(params[AL_APPLINK_DATA]);

    if (paramName in alApplinkDataObj) {
      return alApplinkDataObj[paramName] || null;
    }

    if ('target_url' in alApplinkDataObj) {
      const targetUrl = new URL(alApplinkDataObj.target_url);
      const targetParams = window.m.parseQueryString(
        targetUrl.search.split('?')?.[1]
      );
      return targetParams[paramName] || null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }

  return null;
}

function getParamFromSearchQuery(paramName: string): string | null {
  if (!window.m) {
    return null;
  }

  let params = window.m.parseQueryString(document.location.search);

  if (!params[paramName] && params[AL_APPLINK_DATA]) {
    const paramFromAlApplink = getParamValueFromAlApplink(params, paramName);

    if (paramFromAlApplink) {
      return paramFromAlApplink;
    }
  }

  if (!params[paramName]) {
    params = window.m.parseQueryString(document.location.hash.split('?')?.[1]);
  }

  return params[paramName] || null;
}

export const getFromUrl = {
  referrer: () => getParamFromSearchQuery('referrer') || [document.referrer],
  listingPosition: () => getParamFromSearchQuery('sr'),
  queryId: () => getParamFromSearchQuery('qid'),
  searchQuery: () => getParamFromSearchQuery('text'),
  utmCampaign: () => getParamFromSearchQuery('utm_campaign'),
  ref: () => getParamFromSearchQuery('ref'),
  startedBy: () => getParamFromSearchQuery('started_by'),
  source: () => getParamFromSearchQuery('source'),
  hintChipsClicked: () => getParamFromSearchQuery('hint_chips_click'),
  utmSource: () => getParamFromSearchQuery('utm_source'),
  utmMedium: () => getParamFromSearchQuery('utm_medium'),
  utmContent: () => getParamFromSearchQuery('utm_content'),
  utmTerm: () => getParamFromSearchQuery('utm_term'),
  campaignId: () => getParamFromSearchQuery('campaign_id'),
  fbclid: () => getParamFromSearchQuery('fbclid'),
  ttclid: () => getParamFromSearchQuery('ttclid'),
  gclid: () => getParamFromSearchQuery('gclid'),
  gbraid: () => getParamFromSearchQuery('gbraid'),
  ut: () => getParamFromSearchQuery('ut'),
  categoryCode: () => getParamFromSearchQuery('category_code'),
};
