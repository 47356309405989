export class BffEndpoint {
  static defaultPath = '/yml/bff-sf';

  /**
   * Item-page
   */
  productData = '/item-page/product/:sku';

  /**
   * Orders
   */
  orderDetails = '/orders/:orderId/';
  ordersList = '/orders/';
  orderRecipientDetails = '/orders/recipient/:orderId/';
  orderCancellationReasons = '/orders/:orderId/cancellation-reason';
  orderAddRecipients = '/orders/:orderId/recipients/:phoneNumber';
  orderDeleteRecipients = '/orders/:orderId/recipients/';
  orderExpressTracking = '/orders/:orderId/express-tracking';

  /**
   * Refunds
   */
  refundDetails = '/refunds/details/:id';
  refundDetailsByCode = '/refunds/details/code/:code';
  refundsList = '/refunds/';

  /**
   * Price-trends
   */
  masterDiscounts = '/price-trends/master/discounts';

  /**
   * Other
   */
  userProfile = '/customers/search';
  checkCertificateRecipient = '/customers/certificate-recipient';

  constructor(path = BffEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
