import { CONSTANTS } from '../packages/ks-store/common/const/Constants';
import { Sentry } from '.';

export enum SentryScopes {
  ANALYTIC = 'ANALYTIC',
  DELIVERY_SLOTS = 'DELIVERY_SLOTS',
  REFUND_BUTTON = 'REFUND_BUTTON',
  SUGGEST_ERROR = 'SUGGEST_ERROR',
  DELIVERY_VIEW = 'DELIVERY_VIEW',
  DELIVERY_MAP = 'DELIVERY_MAP',
  LOADING_CHUNK = 'LOADING_CHUNK',
  CONFIGURATOR = 'CONFIGURATOR',
  FAVORITES = 'FAVORITES',
  CATEGORIES = 'CATEGORIES',
  PRODUCT_LIST = 'PRODUCT_LIST',
  OFFER_LIST = 'OFFER_LIST',
  ADS_SERVICE = 'ADS_SERVICE',
  ORDER_VIEW = 'ORDER_VIEW',
  ORDERS = 'ORDERS',
  TRANSLATIONS = 'TRANSLATIONS',
  IMAGE_PICKER = 'IMAGE_PICKER',
  REVIEWS = 'REVIEWS',
  GET_CART = 'GET_CART',
  CART_UPDATE = 'CART_UPDATE',
  CITY_COOKIE = 'CITY_COOKIE',
  PCM_MERCHANT_RECOMMENDATIONS = 'PCM_MERCHANT_RECOMMENDATIONS',
  CAR_PARTS = 'CAR_PARTS',
  PRIZE_DRAW = 'PRIZE_DRAW',
  CHECKOUT_START = 'CHECKOUT_START',
  DISCOUNTS = 'DISCOUNTS',
}

export function logSentryEvent({
  scope,
  message,
  stable,
}: {
  scope: SentryScopes;
  message: string;
  stable?: boolean;
}) {
  if (stable || CONSTANTS.sentryScopes.includes(scope)) {
    Sentry?.captureMessage(`${scope}: ${message}`);
  }
}
