export class KsKaspiEndpoint {
  static defaultPath = '';

  /**
   * Tires
   */
  getCarMarks = '/yml/car-parts/tyres/car-marks';
  getCarModels = '/yml/car-parts/tyres/car-models';
  getCarYears = '/yml/car-parts/tyres/car-years';
  getCarModifications = '/yml/car-parts/tyres/car-modifications';
  getCarTyreSizes = '/yml/car-parts/tyres/car-tyre-sizes';
  getTireDimensionsByCar = '/yml/car-parts/tyres/find-by-car';

  /**
   * Car-parts
   */

  searchByVin = '/yml/car-parts/cars/:vin';
  catalogQuickCategories = '/yml/car-parts/catalog/categories';
  catalogCategoryDetails = '/yml/car-parts/catalog/category-details';
  catalogDetailsByUnit = '/yml/car-parts/catalog/details-by-unit';
  catalogUnits = '/yml/car-parts/catalog/units';
  catalogUnitImage = '/yml/car-parts/catalog/unit-image';
  catalogOemCrosses = '/yml/car-parts/catalog/oem-crosses';
  // catalogBrands = '/yml/car-parts/catalog/brands';
  // catalogStep = '/yml/car-parts/catalog/step';

  constructor(path = KsKaspiEndpoint.defaultPath) {
    for (const key of Object.keys(this)) {
      this[key] = path + this[key];
    }
  }
}
