export interface IElementObserver {
  id: string;
  element: HTMLUnknownElement;
  callback: (item?: any) => void;
  callOnlyOnce?: boolean;
  hasRedraw?: boolean;
}

export class IntersectionObserverService {
  observerElements: IElementObserver[] = [];
  observerOptions: IntersectionObserverInit;
  observer: IntersectionObserver;

  constructor(options?: IntersectionObserverInit) {
    this.observerOptions = options || { rootMargin: '0% 0% 0% 0%' };
    this.observer =
      'IntersectionObserver' in window && this.isConstructorObserver()
        ? new IntersectionObserver(
            this.onIntersection.bind(this),
            this.observerOptions
          )
        : null;
  }

  // * https://caniuse.com/#search=IntersectionObserver
  isConstructorObserver() {
    try {
      new IntersectionObserver(this.onIntersection, {
        rootMargin: '20% 20% 20% 20%',
      });
    } catch (error) {
      if (error.message.indexOf('is not a constructor') >= 0) {
        return false;
      }
    }

    return true;
  }

  onIntersection(entities: IntersectionObserverEntry[]) {
    entities.forEach((item: IntersectionObserverEntry) => {
      if (item.isIntersecting) {
        const id: string = item.target.id;
        const elementIndex = this.observerElements.findIndex(
          (element) => element.id === id
        );
        if (!~elementIndex) {
          return;
        }

        const result = this.observerElements[elementIndex];

        if (!result?.callback) {
          return;
        }

        result.callback();

        if (!result.callOnlyOnce) {
          return;
        }

        this.observer.unobserve(item.target);
        this.observerElements.splice(elementIndex, 1);
      }
    });
  }

  addObserver(data: IElementObserver) {
    if (!this.observer) {
      console.error('IntersectionObserver not supported');
      return;
    }

    const isAlreadyObserve = this.observerElements.find(
      (item) => item.id === data.id
    );

    if (isAlreadyObserve) {
      if (!data.hasRedraw) {
        return;
      }

      const elementIndex = this.observerElements.findIndex(
        (element) => element.id === data.id
      );
      this.observer.unobserve(data.element);
      this.observerElements.splice(elementIndex, 1);
    }

    this.observer.observe(data.element);
    this.observerElements.push(data);
  }

  removeElementFromObserverElements(elementId: string) {
    const index = this.observerElements.findIndex(
      (item) => item.id === elementId
    );
    if (index !== -1) {
      this.observerElements.splice(index, 1);
    }
  }
}

const intersectionObserverService = new IntersectionObserverService();
export const addObserver = (data) =>
  intersectionObserverService.addObserver(data);
