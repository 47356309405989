import { monthlyCalc } from 'ks-utilities/lib/creditCalc';
import formatterNumber from 'ks-utilities/lib/formatterNumber';

import { pipe } from '../../utils/pipe';
import { BadgeType } from '../const/Badge';
import { CONSTANTS } from '../const/Constants';

function updateMonthlyInstallment(
  card: IItemCard,
  priceLiteral: string,
  monthlyPayment: number,
  period: number
) {
  if (!card.loanAvailable) {
    card.monthlyInstallment = null;
    return card;
  }
  card.monthlyInstallment = {
    installment: true,
    formattedPerMonth: `${formatterNumber(monthlyPayment)} ${priceLiteral}`,
    id: period,
  };
  return card;
}

function updateCreditMonthlyPrice(
  card: IItemCard,
  _priceLiteral: string,
  monthlyPayment: number
) {
  card.creditMonthlyPrice = monthlyPayment;
  return card;
}

// Helper to get the default loan period based on the unit price
function getDefaultLoanPeriod(unitPrice: number): number {
  return CONSTANTS.loanPeriod
    .slice() // Prevent mutating the original array with reverse
    .reverse()
    .find((period) => unitPrice > period.fromSum)?.month;
}

// Helper to get loan period from promo, if available
function getPromoLoanPeriod(card: IItemCard): number | undefined {
  return card.promo?.find((promotion) => promotion.type === BadgeType.LOAN)
    ?.priority;
}

export function mutateMonthlyPayment(card: IItemCard, priceLiteral: string) {
  if (!card.unitPrice) {
    return;
  }
  const defaultPeriod = getDefaultLoanPeriod(card.unitPrice);
  const promoPeriod = getPromoLoanPeriod(card);

  const period = promoPeriod
    ? Math.min(promoPeriod, defaultPeriod)
    : defaultPeriod;

  // Если promoPeriod нет, то показываем в зависимости от суммы - показываем максимальный срок по сумме: 3 мес - рассрочка, остальное кредит
  const isInstallment = promoPeriod ? true : defaultPeriod <= 3;

  const monthlyPayment = monthlyCalc(isInstallment, card.unitPrice, period);

  return pipe<IItemCard>(updateMonthlyInstallment, updateCreditMonthlyPrice)(
    card,
    priceLiteral,
    monthlyPayment,
    period
  );
}
