/**
 * @description formatterNumber() helps make formatted price
 */

export default function formatterNumber(num: any) {
  const reg = /(\d)(?=(\d\d\d)+([^\d]|$))/g;

  if (num === undefined || num === null) {
    return num;
  }

  if (typeof num === 'number') {
    num = Number(num.toFixed(2)); // remove '.00'
    num = String(num);
  }

  return num.replace(reg, '$1 ').replace('.', ',');
}
